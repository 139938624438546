@import url('https://fonts.googleapis.com/css?family=Montserrat:400,900|Roboto');
* {
  box-sizing: border-box !important;
  font-family: 'Roboto', sans-serif !important;
  text-decoration: none;
}
@font-face {
  font-family: "Circular";
  src: local("Circular"),
   url("./fonts/circular-std-medium-500.ttf") format("truetype");
  font-weight: bold;
  }
@font-face {
    font-family: "CircularBold";
    src: local("CircularBold"),
     url("./fonts/CircularStd-Bold.woff2") format("truetype");
    font-weight: bold;
}
@font-face {
  font-family: "HandoSoftBold";
  src: local("HandoSoftBold"),
   url("./fonts/HandoSoftTrial-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Tommy";
  src: local("Tommy"),
   url("./fonts/Tommy.otf") format("truetype");
  font-weight: bold;
}
a[href], 
input[type='submit'], 
input[type='image'], 
label[for], 
select,
button{
     cursor: pointer;
} 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
/* margin: 0; */
/* padding: 0; */
/* border: 0; */
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
font-family: Roboto, serif;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.initial{
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 64px;
  height: 64px;
  position: relative;
  background-image:
    linear-gradient(#1D2355 16px, transparent 0) ,
    linear-gradient(#E01E26 16px, transparent 0) ,
    linear-gradient(#E01E26 16px, transparent 0) ,
    linear-gradient(#1D2355 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top , left bottom , right top , right bottom;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    width: 64px;
    height: 64px;
    transform: rotate(0deg)
  }
  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg)
  }
  100% {
    width: 64px;
    height: 64px;
    transform: rotate(360deg)
  }
}
